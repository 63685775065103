import { Component } from "fq-foundation/Scripts/Component";

new Component().create("Header", {
    element: ".f-header",
    resize: function () {

    },
    init: function () {
        var self = this; var lastScrollTop = 0;
        function debounce(method, delay) {
            clearTimeout(method._tId);
            method._tId = setTimeout(function () {
                method();
            }, delay);
        }

        $(window).scroll(function () {
            if (window.scrollY > 200) {
                debounce(handleScroll, 100);
            } else {
                $(self.element + "__wrapper").removeClass("showanimate").removeClass("hideanimate")
            }
        });
        function handleScroll() {
            var st = window.pageYOffset || document.documentElement.scrollTop;
            if (st > 0) {
                if (st <= lastScrollTop) {
                    $(self.element + "__wrapper").removeClass("hideanimate").addClass("showanimate")
                } else {
                    $(self.element + "__wrapper").removeClass("showanimate").addClass("hideanimate")
                }
            }

            lastScrollTop = st <= 0 ? 0 : st;
        }
        $(".sub-menu-checkbox").each(function () {
            var id = $(this).attr("id");
            $(this).find("+label+.sub-menu .back-to-main-menu").each(function () {
                $(this).attr("for", id);
            });
        });
        try {
            document.createEvent("TouchEvent");
            $(".sub-menu-checkbox").on('change', function () {
                if ($(this).prop("checked")) {
                    var c = $(this)
                    $(".sub-menu-checkbox").each(function () {
                        if(!$(this).is(c)) {
                            $(this).prop("checked", false)
                        }
                    })
                    $(".sub-menu-checkbox:checked + label + .sub-menu").offset({
                        top: $(this).closest(".f-header__nav-wrap").parent().prev().height()
                    });
                }
            });
        }
        catch (e) {
            $(self.element).find('.main-menu-item.has-submenu').on('mouseover', function () {
                $(this).find(".sub-menu-checkbox").prop("checked", true);
            });
            $(self.element).find('.main-menu-item.has-submenu').on('mouseout', function () {
                $(this).find(".sub-menu-checkbox").prop("checked", false);
            });
        }
        $(".sub-menu-checkbox-label").on('click', function () {
            var l = $(this).next().find(".sub-menu__wrapper>.column>.columns>.column>.sub-menu-item:eq(0) a").attr("href");
            if (window.innerWidth < 1024) {
                if (l != undefined && l != "") {
                    window.location.href = l;
                }
            }
        });
    }
});
export default Component;