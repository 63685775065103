import { Component } from "fq-foundation/Scripts/Component";

new Component().create("FormInputs", {
    element: ".f-form input[type=color],.f-form input[type=date],.f-form input[type=datetime-local],.f-form input[type=email],.f-form input[type=month],.f-form input[type=number],.f-form input[type=password],.f-form input[type=search],.f-form input[type=tel],.f-form input[type=text],.f-form input[type=time],.f-form input[type=url],.f-form input[type=week],.f-form textarea",
    init: function () {
        // var self = this;
        // $(self.element).on("focus", function () {
        //     $(this).prev("label").hide();
        // });
        // $(self.element).on("change blur", function (e) {
        //     if ($(this).val() == "") { 
        //         $(this).prev("label").show();
        //     } else {
        //         $(this).prev("label").hide();
        //     }
        // })
    }
});

export default Component;