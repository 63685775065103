import { Component } from "fq-foundation/Scripts/Component";
new Component().create("Reviews", {
    element: ".f-reviews",
    currentPage: 1,
    totalPages: 1,
    maxRecords: 10,
    minRating: 5,
    maxRating: 5,
    textLimit: 50,
    maxPagination: 10,
    getUrlParams: function () {
        var d = {};
        var u = window.location.href
        if (u.indexOf("?") > -1) {
            var queryString = u.split("?")[1];
            var kvPair = queryString.split("&");
            kvPair.forEach(function (p) {
                var kp = p.split("=");
                if (d[kp[0].toLowerCase()] == undefined) {
                    d[kp[0].toLowerCase()] = kp[1];
                } else {
                    d[kp[0].toLowerCase()] = [d[kp[0].toLowerCase()]].concat(kp[1]);
                }
            });
            return d;
        } else {
            return
        }
    },
    init: function () {
        var self = this;
        var p = self.getUrlParams();
        if (p != undefined) {
            if (!isNaN(p.currentRating) || p.currentRating != "all") {
                self.minRating = self.maxRating = p.currentrating;
            }
            if (p.currentpageindex !== undefined) {
                self.currentPage = p.currentpageindex;
            }
        }
        self.getReviews(function (reviews, error) {
            var data = Object.assign({}, reviews);
            if (!error) {
                self.totalPages = (data.Total / self.maxRecords).toFixed(0);
                var averageRating = (5 * data["Star5"] + 4 * data["Star4"] + 3 * data["Star3"] + 2 * data["Star2"] + 1 * data["Star1"]) / (data["Star5"] + data["Star4"] + data["Star3"] + data["Star2"] + data["Star1"]);
                $(self.element).find(".review-pagination").attr("data-current", 1).attr("data-total-pages", data.Total);
                $(self.element).find(".review-overall-rating").text(averageRating.toFixed(1));
                $(self.element).find(".review-overview>div>.column>.m-star-rating").attr("data-rating", averageRating.toFixed(1));
                $(self.element).find(".review-overview>div>.column .total-reviews").text(data.Total + " Reviews").attr("href", "?currentRating=All");
                for (var i = 0; i < 5; i++) {
                    $(self.element).find(".reviews-rated-" + (i + 1)).find(".m-progressbar").attr("data-progress", Math.ceil(data["Star" + (i + 1)] / data.Total * 100));
                    var consolidatedReviews = document.createElement("a");
                    consolidatedReviews.href = "?currentRating=" + (i + 1);
                    consolidatedReviews.innerText = data["Star" + (i + 1)] + "";
                    $(self.element).find(".reviews-rated-" + (i + 1)).find(">.column:last").empty().append(consolidatedReviews)
                }
                var reviewListTemplate = $(self.element).find(".review-list .review-item").clone();
                var reviewList = $(self.element).find(".review-list");
                $(reviewList).empty();
                var starRating = $(self.element).find(".review-overview .m-star-rating").clone()
                data.Reviews.forEach(function (a, b, c) {
                    var reviewItem = reviewListTemplate.clone();
                    var s = starRating.clone();
                    $(s).attr("data-rating", a.Stars);
                    $(reviewItem).find(".review-author").text(a.Author);
                    $(reviewItem).find(".review-title").text(a.Title);
                    $(reviewItem).find(".review-rating").append(s);
                    $(reviewItem).find(".review-description").text(a.Text);
                    $(reviewList).append(reviewItem);
                });
                var pagination = self.createPagination(data, $(self.element).find(".review-pagination"));
                var reviewList = self.listReviews(data);
                // $(self.element).find(".review-pagination").append(pagination);
                $(self.element).find(".review-list").append(reviewList);
                var w = 0;
                $(self.element).find(".review-pagination .review-pagination-button").each(function () {
                    w += $(this).outerWidth();
                });
                $(self.element).find(".review-pagination > div").width(w)
                var activeLink = $(self.element).find(".review-pagination .active");
                // var l = $(activeLink).offset().left;
                // console.log(l);
                if (!self.checkInView($(activeLink), false, $(self.element).find(".review-pagination"))) {
                    $(self.element).find(".review-pagination>div").animate({
                        left: -($(activeLink).offset().left - $(activeLink).parent().offset().left) + 100
                    }, 300);
                }
            }
        });
    },
    checkInView: function (elem, partial, parent) {
        var container = $(parent);
        var contWidth = container.width() - 30;
        var contLeft = container.scrollLeft();
        var contBottom = contLeft + contWidth;

        var elemLeft = $(elem).offset().left - container.offset().left;
        var elemRight = elemLeft + $(elem).width();

        var isTotal = (elemLeft >= 0 && elemRight <= contWidth);
        var isPart = ((elemLeft < 0 && elemRight > 0) || (elemLeft > 0 && elemLeft <= container.width())) && partial;

        return isTotal || isPart;
    },
    listReviews: function (data) {
        var self = this;
        var listWrapper = document.createElement("div");
        listWrapper.classList.add("review-list-wrapper");
        data.Reviews.forEach(function (a) {
            var reviewItem = document.createElement("div");
            reviewItem.classList.add("review-item");
            var author = document.createElement("div");
            author.classList.add("review-author");
            author.innerText = a.Author;
            var title = document.createElement("div");
            title.classList.add("review-title");
            title.innerText = a.Title;
            var rating = $(self.element).find(".review-overview .m-star-rating").clone();
            $(rating).attr("data-rating", a.Stars);
            var text = document.createElement("div");
            text.classList.add("review-description");
            text.innerText = a.Text;
            $(reviewItem).append(author);
            $(reviewItem).append(title);
            $(reviewItem).append(rating);
            $(reviewItem).append(text);
            $(listWrapper).append(reviewItem);
        });

        return listWrapper;
    },
    serializeToUrl: function (obj) {
        var str = "";
        for (var key in obj) {
            if (str != "") {
                str += "&";
            }
            str += key + "=" + encodeURIComponent(obj[key]);
        }
        return str;
    },
    createPagination: function (reviews, parent) {
        var self = this;
        var u = window.location.href.split("?");
        var params = {};
        if (u[1] != undefined) {
            var urlParamSet = u[1].split("&");
            urlParamSet.forEach(function (a, b, c) {
                var kvp = a.split("=");
                params[kvp[0]] = kvp[1];
            });
        }
        var pageWrapper = document.createElement("div");
        var prevButton = document.createElement("a");
        prevButton.innerHTML = "Prev";
        prevButton.classList.add("review-pagination-prev");
        if (self.currentPage == 1) {
            prevButton.classList.add("disabled");
        }
        prevButton.classList.add("review-pagination-button");
        var prevPage = (params["currentPageIndex"] != undefined && !isNaN(params["currentPageIndex"] && params["currentPageIndex"] != 0)) ? Number(params["currentPageIndex"] - 1) : 1;
        var prevParamSet = Object.assign({}, params);
        prevParamSet["currentPageIndex"] = prevPage;
        prevButton.href = "?" + self.serializeToUrl(prevParamSet);
        $(parent).append(prevButton);
        var nextButton = document.createElement("a");
        nextButton.classList.add("review-pagination-button");
        nextButton.classList.add("review-pagination-next");
        if (self.currentPage == (self.totalPages)) {
            nextButton.classList.add("disabled");
        }
        nextButton.innerHTML = "Next";
        var nextPage = (prevPage + 2 > reviews.total) ? -1 : prevPage + 5;
        var nextParamSet = Object.assign({}, params);
        nextParamSet["currentPageIndex"] = nextPage;
        nextButton.href = "?" + self.serializeToUrl(nextParamSet);
        var a = 0;
        for (var i = 0; i < reviews.Total; i += 10) {
            a = i / 10;
            var btn = document.createElement("a");
            btn.classList.add("review-pagination-button");
            var btnParamSet = Object.assign({}, params);
            if (self.currentPage == (a + 1)) {
                btn.classList.add("active");
            }
            btnParamSet["currentPageIndex"] = a + 1;
            btn.innerHTML = a + 1;
            btn.href = "?" + self.serializeToUrl(btnParamSet);
            $(pageWrapper).append(btn);
        }
        $(parent).append(pageWrapper);
        $(parent).append(nextButton);
    },
    getReviews: function (callback) {
        var self = this;
        var data = {
            pageSize: self.maxRecords,
            rating: self.maxRating,
            textLimit: self.textLimit,
            page: self.currentPage
        }
        $.ajax({
            url: "/api/reviews/getreviewsall",
            data: data,
            crossDomain: true,
            success: function (data) {
                callback(JSON.parse(data), null);
            },
            error: function (d) {
                callback(d, "error");
            }
        })
    }
});
export default Component;