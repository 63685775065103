import { Component } from "fq-foundation/Scripts/Component"
import "fq-feature/Slider/src/Scripts/main"

var slider = new Component().create("Slider", {
    element: ".f-slider",
    sliderElement: ".f-slide__wrapper",
    init: function () {
        var self = this;
        $(this.element).each(function () {
            var curEl = $(this);
            var options = JSON.parse(JSON.stringify($(this).data('slick-settings')));
            options = (options === "") ? {} : options;
            options.appendDots = undefined;
            self.setArrowAndDotsColors(options, curEl);
            self.initialize($(this), $(this).find(self.sliderElement), options);
        });
    }
});