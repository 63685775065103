import { Component } from "./Component";

new Component().create("DatePicker", {
    element: "input[type=date]",
    loadScript: function (url, callback) {
        var script = document.createElement("script")
        script.type = "text/javascript";
        if (script.readyState) {
            script.onreadystatechange = function () {
                if (script.readyState == "loaded" ||
                    script.readyState == "complete") {
                    script.onreadystatechange = null;
                    callback("Success");
                }
            };
        } else {
            script.onload = function () {
                callback("Success");
            };
        }
        script.onerror = function () {
            callback("Error")
        }
        script.src = url;
        document.body.appendChild(script);
    },
    init: function () {
        var self = this;
        if (isIE()) {
            self.loadScript("//code.jquery.com/ui/1.12.1/jquery-ui.js", function () {
                $(self.element).datepicker({
                    changeMonth: true,
                    changeYear: true,
                    showButtonPanel: true,
                    showOn: "button"
                });
            });
        }
    }
});
export default {};